/* eslint-disable react/no-danger */
import * as React from 'react'
import { graphql } from 'gatsby'
import { Container, makeStyles, Theme, createStyles } from '@material-ui/core'
import IndexLayout from './IndexLayout'
import Hero from '../components/Hero'

import heroVideoDesktop from '../videos/hero-desktop-cropped-hd.webm'
import heroVideoMobile from '../videos/hero-mobile-cropped-hd.webm'
import heroPosterDesktop from '../videos/hero-desktop-thumbnail-hd.webp'
import heroPosterMobile from '../videos/hero-mobile-thumbnail-hd.webp'

interface PageTemplateProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
        author: {
          name: string
          url: string
        }
      }
    }
    markdownRemark: {
      html: string
      id: string
      frontmatter: {
        title: string
        description: string
        heroTitle: string
        heroBody: string
        ctaLink: string
        ctaText: string
      }
    }
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mdxSection: {
      textAlign: 'left'
    }
  })
)

const PageTemplate: React.SFC<PageTemplateProps> = ({ data }) => {
  const classes = useStyles()
  return (
    <IndexLayout title={data.markdownRemark.frontmatter.title} description={data.markdownRemark.frontmatter.description}>
      <Hero
        topHero
        inverse
        videoSourceDesktop={heroVideoDesktop}
        videoSourceMobile={heroVideoMobile}
        videoPosterDesktop={heroPosterDesktop}
        videoPosterMobile={heroPosterMobile}
        mainText={data.markdownRemark.frontmatter.heroTitle}
        bodyText={
          <>
            {data.markdownRemark.frontmatter.heroBody}
          </>
        }
        ctaLink={data.markdownRemark.frontmatter.ctaLink}
        ctaText={data.markdownRemark.frontmatter.ctaText}
        IncluceDownArrow={false}
      />
      <Container maxWidth="md">
        <div className={classes.mdxSection} dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
      </Container>
    </IndexLayout>
  )
}

export default PageTemplate

export const query = graphql`
  query PageWithHeroTemplateQuery($id: String!) {
    site {
      siteMetadata {
        title
        description
        author {
          name
          url
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        heroTitle
        heroBody
        ctaLink
        ctaText
      }
    }
  }
`
